export const environment = {
  production: true,
  environment: 'Production',
  version: '24.05.615',
  keycloakConfiguration: {
    url: 'https://auth.atrify.com/auth',
    realm: 'eudamed',
    clientId: 'eudamed'
  },
  socket: {
    destination: '/topic/notification',
    url: 'http://localhost:7025/udimanager/socket'
  }

};
